@import "../../assets/styles/variables.scss";

.header {
  background-color: $beige;
  position: sticky;
  top: 0;
  z-index: 10;
  width: 100%;
  margin: auto;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
}

.header * {
  width: max-content;
  color: $black;
  text-decoration: none;
  list-style-type: none;
}

.header *a::after {
  display: block;
  content: "";
  width: 0%;
  height: 2px;
  background-color: $bordo;
  transition: width 0.4s ease-in-out;
}

.header *a:hover::after {
  height: 2px;
  width: 100%;
}

header *.active::after {
  display: block;
  content: "";
  height: 2px;
  width: 100%;
  background-color: $bordo;
}

@media (max-width: 1024px) {
  header *.active::after {
    height: 1px;
  }
}

header {
  &.hide {
    top: -15.5em;
  }
  &.show {
    top: 0;
  }
}

.container {
  display: flex;
  justify-content: space-between;
  width: 76.5%;
  min-width: 600px;
  padding: 20px 40px 0;
}

.download {
  height: min-content;
  padding-top: 7px;
  font-size: 24px;
  font-family: $nonBold;
  line-height: 1em;
}

.logo {
  font-size: 40px;
  font-family: $nonBold;
  letter-spacing: 0.03em;
  line-height: 1em;
}

.links {
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 14px 0 0 119px;
  font-size: 24px;
  font-family: $nonRegular;
  letter-spacing: 0.03em;
  line-height: 1.1em;
}

@media (max-width: 1024px) {
  header *a::after {
    height: 1px;
  }

  .container {
    min-width: 200px;
  }

  .download {
    font-size: 16px;
  }

  .logo {
    font-size: 24px;
  }

  .links {
    padding: 5px 0 0 71px;
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .container {
    width: 100%;
    padding: 10px;
  }
}
