@import "../../assets/styles/variables.scss";

.main {
  margin: 5% 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .main_images {
    flex: none;

    .main_images-item {
      width: 445px;
      height: 380px;
      object-fit: contain;
      transition: transform 2s;
    }

    &.fade {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .main_title {
    max-width: 842px;
    font-family: $nonBold;
    font-size: 64px;
    margin-left: 25px;
  }
}

@media screen and (max-width: 389px) {
  .main {
    display: flex;
    flex-direction: column-reverse;
    margin: 40px auto;

    .main_title {
      max-width: 300px;
      font-size: 28px;
      font-family: $nonBold;
      margin: 0 auto;
      padding: 0;
    }

    .main_images {
      max-width: 250px;
      transition: transform 2s;
      margin: 0 auto;

      .main_images-item {
        width: 250px;
        height: auto;
        transition: transform 2s;
        transform: translateY(-35px);
        position: relative;
        z-index: -100;
      }
    }
  }
}

@media screen and (min-width: 390px) and (max-width: 430px) {
  .main {
    display: flex;
    flex-direction: column-reverse;
    margin: 80px auto;

    .main_title {
      max-width: 390px;
      font-size: 36px;
      font-family: $nonBold;
      margin: 0 auto;
      padding: 0;
    }

    .main_images {
      max-width: 350px;
      transition: transform 2s;
      margin: 0 auto;

      .main_images-item {
        width: 350px;
        height: auto;
        transition: transform 2s;
        transform: translateY(-35px);
        position: relative;
        z-index: -100;
      }
    }
  }
}

@media screen and (min-width: 431px) and (max-width: 767px) {
  .main {
    display: flex;
    flex-direction: column-reverse;
    margin: 150px auto;

    .main_title {
      max-width: 520px;
      font-size: 48px;
      font-family: $nonBold;
      margin: 0 auto;
      padding: 0;
    }

    .main_images {
      max-width: 400px;
      transition: transform 2s;
      margin: 0 auto;

      .main_images-item {
        width: 400px;
        height: auto;
        transition: transform 2s;
        transform: translateY(-35px);
        position: relative;
        z-index: -100;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) and (max-height: 820px) {
  .main {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 50px auto;

    .main_title {
      max-width: 450px;
      font-size: 42px;
      font-family: $nonBold;
    }

    .main_images {
      max-width: 350px;
      transition: transform 2s;

      .main_images-item {
        width: 350px;
        height: auto;
        transition: transform 2s;
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) and (min-height: 821px) {
  .main {
    display: flex;
    flex-direction: column-reverse;
    margin: 150px auto;

    .main_title {
      max-width: 400px;
      font-size: 42px;
      font-family: $nonBold;
    }

    .main_images {
      max-width: 400px;
      transition: transform 2s;

      .main_images-item {
        width: 400px;
        height: auto;
        transition: transform 2s;
        transform: translateY(-35px);
        position: relative;
        z-index: -100;
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399px) {
  .main {
    display: flex;
    align-items: center;
    justify-content: center;
    .main_title {
      max-width: 700px;
      font-size: 52px;
    }
  }
}

@media screen and (min-width: 1981px) {
  .main {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10% 0 0 0;

    .main_title {
      max-width: 900px;
      font-size: 64px;
    }
  }
}
