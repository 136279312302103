@import "../../assets/styles/variables.scss";

.container {
    margin: 37vh 0 0 0;
    padding: 0 40px;
    max-width: 60%;
    * {
        color: $black;
    }
}

.big {
    font-size: 64px;
    font-family: $nonBold;
}

@media (max-width: 1024px) {
    .big {
        font-size: 40px;
    }
}

@media (max-width: 768px) {
    .container {
        margin: 30vh 0 0 0;
        padding: 0 10px;
        max-width: 95%;
        * {
            color: $black;
        }
    }
}

.smallContainer {
    padding: 15px 0;
}

@media (max-width: 768px) {
    .smallContainer {
        padding: 10px 0;
    }
}

.small {
    line-height: 1.3em;
    letter-spacing: 0.03em;
    font-size: 24px;
    font-family: $nonRegular;
}

@media (max-width: 1024px) {
    .small {
        font-size: 14px;
    }
}

.link {
    display: block;
    line-height: 0.8em;
    letter-spacing: 0.06em;
    padding: 29px 0;
    font-size: 36px;
    font-family: $nonRegular;
    width: max-content;
    &::after {
        display: block;
        content: "";
        width: 100%;
        height: 3px;
        background-color: $bordo;
    }
}

@media (max-width: 1024px) {
    .link {
        font-size: 16px;
        &::after {
            height: 1px;
        }
    }
}

@media (max-width: 768px) {
    .link {
        padding: 20px 0;
    }
}
