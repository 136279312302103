@import "./../../assets/styles/variables.scss";

a {
  text-decoration: none;
}

.container {
  margin-bottom: 80px;
  .wrapper {
    max-width: 1150px;
    display: flex;
    align-items: flex-end;
    gap: 10px;
    .img_wrapper {
      max-width: 1005px;
      cursor: pointer;
      img,
      video {
        width: 100%;
      }
    }
    .mobile {
      display: none;
    }
    .notloaded {
      display: none;
    }
    .number {
      font-family: $dmsans;
      font-size: 96px;
      font-weight: 400;
      line-height: 92px;
      color: $black;
    }
  }
  .description {
    margin-top: 20px;
    max-width: 730px;
    font-family: $dmsans;
    font-size: 36px;
    font-weight: 500;
    line-height: 100%;
    color: $black;
    cursor: pointer;
  }
}

@media screen and (max-width: 1110px) {
  .container {
    margin-bottom: 80px;
    .wrapper {
      .number {
        font-size: 76px;
        line-height: 74px;
      }
    }
    .description {
      max-width: 556px;
      font-size: 26px;
    }
  }
}

@media screen and (max-width: 890px) {
  .container {
    margin-bottom: 40px;
    .wrapper {
      .number {
        font-size: 46px;
        line-height: 48px;
      }
    }
    .description {
      max-width: 362px;
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 506px) {
  .container {
    margin-bottom: 30px;
    .wrapper {
      gap: 5px;
      .number {
        font-size: 32px;
      }
      .number_video {
        line-height: 28px;
      }
      .number_img {
        line-height: 36px;
      }
      .mobile {
        display: block;
      }
      .desktop {
        display: none;
      }
    }
    .description {
      max-width: 260px;
      font-size: 20px;
    }
  }
}
