@import "../../assets/styles/variables.scss";

.container {
    font-family: $dmsans;
    background-color: $beige;
    color: $black;
    padding: 60px 100px 100px;
    font-style: normal;
}

.header{
    .subtitle{
        font-weight: 500;
        font-size: 20px;
        line-height: 110%;
    
}
    .title {
        font-weight: 500;
        font-size: 36px;
        line-height: 110%;        
        padding: 20px 0 40px;
    }
}
.mainsection{
    display: flex;
    flex-direction: column;
    gap: 100px;
}
.wrapper{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 187px;

    .image {
        align-self: center;
        width: 100%;
        height: auto;
        }
        .image2{
            display: none;
        }
}

.paragraph{
    font-weight: 500;
    font-size: 36px;
    line-height: 110%;     
}

.textitem{
    
    font-weight: 400;
    font-size: 24px;
    line-height: 31px;
    padding-bottom: 20px;
}


.media {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
}

.medianame {
    font-family: $nonBold;
    font-weight: 700;
    font-size: 64px;
    line-height: 77px;
}

.link {
    padding-top: 20px;
    display: block;
    font-weight: 500;
    font-size: 36px;
    line-height: 110%;
    color: $black;
    text-decoration: none;
    transition: 0.3s all;

    &:hover {
        color: $gray;
    }
  }

@media screen and (max-width: 1300px) and (min-width: 900px) {
    .container {
        padding: 30px 70px 50px;
    }
    .header{
        .title {
            font-size: 28px;
        }
    }
    .wrapper{
        grid-gap: 50px;
    }
    .paragraph{
        font-size: 28px;  
    }
    .textitem{
        font-size: 20px;
    }
    .medianame {
        font-size: 56px;
    }
    
    .link {        
        font-size: 28px;
      }
}
@media screen and (max-width: 899px) and (min-width: 460px) {
    .container {
        padding: 20px 30px 50px;
    }
    .header{
        .subtitle{
            font-size: 16px;        
    }

        .title {
            font-size: 20px;
        }
    }
    .mainsection{
        gap: 20px;
    }

    .wrapper{
        grid-template-columns: 1fr;
        align-items: center;
        grid-gap: 15px;
    }
    .paragraph{
        font-size: 20px;  
    }
    .textitem{
        font-size: 14px;
        line-height: 14px;
    }
    .media {
        margin-top: 20px;
    }

    .medianame {
        font-size: 48px;
    }
    
    .link {        
        font-size: 20px;
      }
}
@media screen and (max-width: 459px) and (min-width: 200px) {
    .container {
        padding: 20px 30px 50px;
    }
    .header{
        .subtitle{
            font-size: 10px;        
    }
        .title {
            font-size: 16px;
            padding: 10px 0 20px;
        }
    }
    .mainsection{
        gap: 60px;
    }

    .wrapper{
        grid-template-columns: 1fr;
        align-items: center;
        grid-gap: 15px;
        .image {
            display: none;

            }
            .image2{
                display: block;
                align-self: center;
                width: 100%;
                height: auto;
            }
    }
    .paragraph{
        font-size: 16px;  
    }
    .textitem{
        font-size: 14px;
        line-height: 14px;
    }
    .media {
        margin-top: 60px;
    }

    .medianame {
        font-size: 36px;
        line-height: 40px;
    }
    
    .link {        
        padding-top: 15px;
        font-size: 16px;
      }
}