@import "./../../assets/styles/variables.scss";

.portfolio {
  margin: 60px auto 0;
  padding: 0px 40px;
  .title {
    display: flex;
    gap: 50px;
    margin-bottom: 40px;
    h2 {
      font-family: $nonBold;
      font-size: 96px;
      color: $gray;
      cursor: pointer;
      transition: 0.3s all;
      &:hover {
        color: $black;
      }
    }
    span {
      font-family: $nonBold;
      font-size: 96px;
      color: $black;
    }
    .active {
      color: $black;
    }
  }
}

@media screen and (max-width: 1110px) {
  .portfolio {
    .title {
      gap: 50px;
      margin-bottom: 40px;
      h2 {
        font-size: 76px;
      }
      span {
        font-size: 76px;
      }
    }
  }
}

@media screen and (max-width: 890px) {
  .portfolio {
    padding: 0px 20px;
    .title {
      gap: 20px;
      margin-bottom: 20px;
      h2 {
        font-size: 46px;
      }
      span {
        font-size: 46px;
      }
    }
  }
}

@media screen and (max-width: 506px) {
  .portfolio {
    margin: 40px auto 0;
    padding: 0px 10px;
    .title {
      gap: 15px;
      margin-bottom: 20px;
      h2 {
        font-size: 32px;
      }
      span {
        font-size: 32px;
      }
    }
  }
}
