@import "../../assets/styles/variables.scss";

.wrapper {
  // margin: 20px auto;
  padding: 20px 40px;
  font-family: $dmsans;
  font-size: 24px;
  font-weight: 500;
  transition: 0.3s all;

  ul {
    list-style-type: none;

    a {
      &:hover {
        color: $gray;
      }
    }
  }
}

.links_list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.rightLinks {
  margin-left: auto;
}

.linkedin {
  color: $black;
  text-decoration: none;
}

.telegram {
  color: $black;
  text-decoration: none;
  //   padding-left: 264px;
}

.facebook {
  color: $black;
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .wrapper {
    padding: 10px;
    font-weight: 600;
    font-size: 14px;

    .rightLinks {
      margin-left: 0;
    }
    .links_list {
      display: flex;
      justify-content: space-between;
    }
  }
}
