@import "../src/assets/styles/variables.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: $beige;
  max-width: 1600px;
  margin: auto;
}

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.app-container {
  flex: 1 0 auto;
}

footer {
  flex: 0 0 auto;
}
