@import "../../assets/styles/variables.scss";

.container {
  padding: 12vh 40px 0;
  min-width: 600px;
  width: 71%;
}

.info {
  color: $black;
  font-size: 48px;
  font-family: $dmsans;
  font-weight: 500;
  letter-spacing: 0.03em;
}

.smalInfo {
  font-family: $nonRegular;
  font-size: 36px;
  padding-top: 20px;
  letter-spacing: 0.04em;
}

@media (max-width: 1281px) {
  .container {
    padding: 5vh 40px 0;
  }
}

@media (max-width: 1224px) {
  .info {
    font-size: 40px;
  }
  .smalInfo {
    font-size: 30px;
  }
}

@media (max-width: 1224px) and (orientation: portrait) {
  .container {
    padding: 12vh 40px 0;
  }
}

@media (max-width: 1024px) {
  .container {
    min-width: 200px;
    width: 75%;
  }

  .info {
    font-size: 22px;
  }

  .smalInfo {
    font-size: 16px;
    padding-top: 10px;
  }
}

@media (max-width: 1024px) and (orientation: portrait) {
  .container {
    padding: 27vh 40px 0;
  }
}

@media (max-width: 767px) {
  .container {
    width: 95%;
    padding: 27vh 10px 0;
  }
}
