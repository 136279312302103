@import "../../assets/styles/variables.scss";

.project-container {
  color: $black;
  font-family: $dmsans;
  font-style: normal;
  padding: 60px 40px 100px;
}

//////////////////////////////////////////////////header block//////////////////////////////////////////////////
.project-header {
  display: flex;
  justify-content: space-between;
  align-items: end;
  padding: 0px 11.5px 20px;
  .project-header__title {
    max-width: 50%;
  }
  .project2-header__title {
    max-width: 80%;
  }
  .project5-header__title {
    max-width: 70%;
  }
  h2 {
    font-size: 64px;
    font-weight: 400;
    line-height: 64px;
  }
  .project-header__number {
    font-size: 96px;
    font-weight: 400;
    line-height: 125px;
    text-align: left;
  }
}

//////////////////////////////////////////////////pictures block//////////////////////////////////////////////////
.project-gallery {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

img.project-gallery__item {
  object-fit: cover;
  aspect-ratio: 16 / 9;
}

.item_horizontal {
  grid-column: 1 / span 2;
  width: 100%;
  height: auto;
}

.item_horizontal2 {
  grid-column: 1 / span 2;
  width: 100%;
  height: auto;
}

.item_horizontal3 {
  grid-column: 1 / span 2;
  padding-bottom: 100px;
  width: 100%;
  height: auto;
}

.item_horizontal4 {
  flex: 1;
  object-fit: cover;
  height: auto;
}

.item_vertical {
  grid-column: span 1;
  width: 100%;
  height: auto;
}
.item_vertical2 {
  width: 40%;
  height: auto;
}
.project-gallery2 {
  display: grid;
  gap: 20px;
}

.project-gallery3 {
  padding: 20px 0 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.project-gallery3__item {
  width: auto;
  height: auto;
  max-height: 100%;
  min-height: 180px;
}

.project-gallery3__wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.project-gallery3__item {
  img {
    object-fit: cover;
    object-position: bottom;
  }
}

.project-gallery3__item:last-child {
  img {
    object-position: center;
  }
}

.project-gallery4 {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.project-gallery5 {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.project-gallery6 {
  padding-top: 20px;
  display: flex;
  gap: 20px;
}

//////////////////////////////////////////////////article block//////////////////////////////////////////////////
.project-article {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 80px 0;

  .project-article__item1 {
    padding: 10px 0;
    font-size: 36px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    max-width: 65%;
    width: 100%;
  }

  .project4-article__item1 {
    font-size: 24px;
    font-weight: 400;
    line-height: 31px;
    max-width: 65%;
    width: 100%;
    .project4-article__text {
      padding: 20px 0;
    }
  }

  .project-article__title {
    padding-top: 40px;
    font-size: 36px;
    text-transform: uppercase;
  }

  .project-article__subtitle {
    font-weight: 400;
    padding-top: 40px;
    text-transform: uppercase;
  }

  .project-article__item2 {
    width: 65%;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    font-size: 24px;
    font-weight: 400;
    line-height: 31.25px;
    padding-top: 40px;
    p {
      padding: 20px 0;
    }
  }
}
.project3-article {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 100px 0 40px;
  .project-article__item1 {
    font-size: 36px;
    font-weight: 500;
    line-height: 40px;
    text-align: left;
    max-width: 65%;
    width: 100%;
  }

  .project4-article__item1 {
    font-size: 24px;
    font-weight: 400;
    line-height: 31px;
    max-width: 65%;
    width: 100%;
    .project4-article__text {
      padding: 20px 0;
    }
  }

  .project-article__title {
    padding-top: 40px;
    font-size: 36px;
    text-transform: uppercase;
  }
}

//////////////////////////////////////////////////team block//////////////////////////////////////////////////
.project-team {
  padding: 80px 40px;
}

.project-team__title {
  margin-bottom: 20px;
  font-size: 36px;
  font-weight: 500;
  line-height: 36px;
}

.project-team__container {
  column-count: 2;
}

.project4-team__container {
  column-count: 1;
  display: flex;
  justify-content: flex-start;
}

.project-team__item {
  padding-bottom: 5px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 75px;
}

.project-team__item_job {
  padding-bottom: 5px;
  color: $gray;
  font-size: 24px;
  font-weight: 500;
  line-height: 26.4px;
}

.project-team__item_name {
  padding-bottom: 5px;
  font-size: 24px;
  font-weight: 500;
  line-height: 26.4px;
  text-transform: uppercase;
}

//////////////////////////////////////////////////switcher block//////////////////////////////////////////////////
.project-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  transition: 0.3s all;
  &:hover {
    color: $gray;
  }
}
.project-switch__link {
  position: relative;
  text-decoration: none;
  color: inherit;
}

.project-switch__title {
  padding: 40px 22.5px;
  font-size: 36px;
  max-width: 50%;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
}

.project-switch__number {
  font-size: 96px;
  font-weight: 400;
  line-height: 105.6px;
  text-align: left;
}

//////////////////////////////////////////////////adaptive styles//////////////////////////////////////////////////
@media screen and (max-width: 1600px) and (min-width: 1100px) {
  .project-header {
    h2 {
      font-size: 50px;
      line-height: 50px;
    }
    .project-header__title {
      max-width: 65%;
    }
    .project2-header__title {
      max-width: 75%;
    }
    .project5-header__title {
      max-width: 65%;
    }
    .project-header__number {
      font-size: 76px;
    }
  }
  .project3-article {
    padding: 40px 0 20px;
    .project-article__item1 {
      font-size: 24px;
      line-height: 27px;
      max-width: 60%;
    }

    .project4-article__item1 {
      font-size: 20px;
      line-height: 22px;
      padding-top: 20px;
      max-width: 60%;
      .project4-article__text {
        padding: 10px 0;
      }
    }

    .project-article__title {
      padding-top: 40px;
      font-size: 26px;
    }

    .project-article__subtitle {
      padding-top: 20px;
    }

    .project-article__item2 {
      max-width: 60%;
      font-size: 20px;
      line-height: 27px;
      padding-top: 20px;
      p {
        padding: 10px 0;
      }
    }
  }
  .project-article {
    padding: 40px 0;

    .project-article__item1 {
      font-size: 24px;
      line-height: 32.2px;
      max-width: 60%;
    }

    .project4-article__item1 {
      font-size: 20px;
      line-height: 27px;
      padding-top: 20px;
      max-width: 60%;
      .project4-article__text {
        padding: 10px 0;
      }
    }

    .project-article__title {
      padding-top: 40px;
      font-size: 26px;
    }

    .project-article__subtitle {
      padding-top: 20px;
    }

    .project-article__item2 {
      max-width: 60%;
      font-size: 20px;
      line-height: 27px;
      padding-top: 20px;
      p {
        padding: 10px 0;
      }
    }
  }

  .project-team__item {
    column-gap: 50px;
  }

  .project-team__item_job {
    font-size: 14px;
    line-height: 16.4px;
  }

  .project-team__item_name {
    font-size: 14px;
    line-height: 16.4px;
  }
  .project-switch__title {
    padding: 20px 12.5px;
    font-size: 24px;
    line-height: 26px;
  }

  .project-switch__number {
    font-size: 76px;
    line-height: 85.6px;
  }
}

@media screen and (max-width: 1099px) and (min-width: 551px) {
  .project-container {
    padding: 60px 20px 70px;
  }
  .project-header {
    .project-header__title {
      max-width: 65%;
    }
    .project2-header__title {
      max-width: 65%;
    }
    .project5-header__title {
      max-width: 65%;
    }
    h2 {
      font-weight: 500;
      font-size: 32px;
      line-height: 35px;
    }
    .project-header__number {
      font-size: 55px;
    }
  }
  .project3-article {
    padding: 20px 0 10px;
    .project-article__item1 {
      max-width: 70%;
      font-size: 20px;
      line-height: 24px;
    }

    .project4-article__item1 {
      max-width: 70%;
      font-size: 18px;
      line-height: 21.25px;
      padding-top: 10px;
      .project4-article__text {
        padding: 5px 0;
      }
    }

    .project-article__title {
      font-size: 18px;
      padding-top: 10px;
    }
    .project-article__subtitle {
      padding-top: 10px;
    }
    .project-article__item2 {
      max-width: 70%;
      font-size: 18px;
      line-height: 18px;
      padding-top: 10px;
      p {
        padding: 5px 0;
      }
    }
  }
  .project-article {
    padding: 20px 0;
    .project-article__item1 {
      max-width: 70%;
      font-size: 20px;
      line-height: 24px;
    }

    .project4-article__item1 {
      max-width: 70%;
      font-size: 18px;
      line-height: 21.25px;
      padding-top: 10px;
      .project4-article__text {
        padding: 5px 0;
      }
    }

    .project-article__title {
      font-size: 18px;
      padding-top: 10px;
    }
    .project-article__subtitle {
      padding-top: 10px;
    }
    .project-article__item2 {
      max-width: 70%;
      font-size: 18px;
      line-height: 21.25px;
      padding-top: 10px;
      p {
        padding: 5px 0;
      }
    }
  }

  .project-team {
    padding: 40px 0;
  }

  .project-team__item {
    align-items: center;
    column-gap: 35px;
  }

  .project-team__item_job {
    font-size: 14px;
    line-height: 14px;
  }

  .project-team__item_name {
    font-size: 14px;
    line-height: 14px;
  }
  .project-switch__title {
    padding: 20px 6.5px;
    font-size: 18px;
    line-height: 16px;
    max-width: 70%;
  }

  .project-switch__number {
    font-size: 48px;
    line-height: 48px;
  }
}

@media screen and (max-width: 550px) and (min-width: 200px) {
  .project-container {
    padding: 40px 10px 50px;
  }
  .project-header {
    padding: 0 0 15px;
    .project-header__title {
      max-width: 90%;
    }
    .project2-header__title {
      max-width: 80%;
    }
    .project5-header__title {
      max-width: 80%;
    }
    h2 {
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
    }
    .project-header__number {
      display: none;
    }
  }
  .project3-article {
    padding: 40px 0 10px;
    .project-article__item1 {
      max-width: 100%;
      font-size: 16px;
      line-height: 110%;
    }

    .project4-article__item1 {
      padding: 0 0;
      max-width: 100%;
      font-size: 14px;
      line-height: 18px;
      .project4-article__text {
        padding: 5px 0 10px;
      }
    }

    .project-article__title {
      font-size: 20px;
      line-height: 120%;
      padding-top: 15px;
    }
    .project-article__subtitle {
      padding-top: 15px;
    }
    .project-article__item2 {
      align-self: baseline;
      width: 100%;
      font-size: 14px;
      line-height: 120%;
      padding-top: 15px;
      p {
        padding: 10px 0;
      }
    }
  }
  .project-article {
    padding: 40px 0;
    .project-article__item1 {
      max-width: 100%;
      font-size: 16px;
      line-height: 110%;
    }

    .project4-article__item1 {
      padding: 0 0;
      max-width: 100%;
      font-size: 14px;
      line-height: 18px;
      .project4-article__text {
        padding: 5px 0 10px;
      }
    }

    .project-article__title {
      font-size: 20px;
      line-height: 120%;
      padding-top: 10px;
    }
    .project-article__subtitle {
      padding-top: 15px;
    }
    .project-article__item2 {
      align-self: baseline;
      width: 100%;
      font-size: 14px;
      line-height: 120%;
      padding-top: 15px;
      p {
        padding: 10px 0;
      }
    }
  }

  .project-gallery {
    grid-template-columns: 1fr;
    gap: 5px;
  }
  .project-gallery2 {
    padding: 5px 0;
    gap: 5px;
  }
  .project-gallery3 {
    padding: 5px 0;
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
  }
  .project-gallery4 {
    padding-top: 0;
    grid-template-columns: repeat(1, 1fr);
    gap: 5px;
  }
  .project-gallery5 {
    padding-top: 0;
    gap: 5px;
  }
  .item_horizontal {
    grid-column: 1 / span 1;
  }

  .item_horizontal3 {
    grid-column: 1 / span 2;
    padding-bottom: 50px;
  }

  .project-gallery3__wrapper {
    gap: 5px;
  }
  .project-team {
    padding: 50px 0;
  }

  .project-team__title {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 20px;
  }
  .project-team__container {
    column-count: 1;
  }

  .project-team__item {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
    align-items: center;
  }

  .project-team__item_job {
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 100%;
  }

  .project-team__item_name {
    padding-bottom: 10px;
    font-size: 14px;
    line-height: 100%;
  }
  .project-switch {
    justify-content: space-between;
  }
  .project-switch__title {
    padding: 0 15px 0 0;
    font-size: 20px;
    line-height: 100%;
    max-width: 70%;
  }

  .project-switch__number {
    font-size: 35px;
    line-height: 100%;
  }
}
